(function () {
  'use strict';

  const fields = [
        'id',
        'placa',
        'prefixo',
        'uo.nome',
        'uo.id',
        'grupo.nome',
        'tipo',
        'modelo',
        'marca',
        'ano',
        'hodometro',
        'status',
        'timezone',
        'chassi',
        'horimetro',
        'tipoPermissao',
        'intervaloManutencao',
        'margemAlertaManutencao',
        'hodometroProximoAlertaManutencao',
        'intervaloManutencaoHorimetro',
        'margemAlertaManutencaoHorimetro',
        'horimetroProximoAlertaManutencao',
        'situacaoVeiculo.id',
        'situacaoVeiculo.nome',
        'dataRetorno',
        'vigenciaProgramada',
        'ultimoSyncTtc',
        'ultimoSyncVfleets',
        'tipoVeiculo.id',
        'tipoVeiculo.nome',
        'tipoVeiculo.nameEn',
        'tipoVeiculo.nameEs'
      ],
      VEICULO_URL = 'veiculo/v2/veiculos';
  class CadastroVeiculoService {
    constructor(Restangular, EQUIPAMENTO) {
      this.restangular = Restangular;
      this.equipamentosSensorAcionado = [
        EQUIPAMENTO.ACIONAMENTO_ELETRONICO,
        EQUIPAMENTO.ACIONAMENTO_PNEUMATICO,
        EQUIPAMENTO.SENSOR_HIDRAULICO,
        EQUIPAMENTO.SENSOR_HIDRAULICO_HR36,
        EQUIPAMENTO.SENSOR_HIDRAULICO_PV,
        EQUIPAMENTO.SENSOR_HIDRAULICO_PV_NEO,
        EQUIPAMENTO.SENSOR_HIDRAULICO_HR48,
        EQUIPAMENTO.SENSOR_HIDRAULICO_HR24,
        EQUIPAMENTO.SENSOR_HIDRAULICO_EPP36
      ];
    }

    getByUo(uoId, uoDonaId, veiculoId) {
      return this.restangular.one(VEICULO_URL)
      .get({
        uoPermissao: uoId,
        uoId: uoDonaId,
        veiculoId: veiculoId,
        size: 100000,
        fields: fields
      });
    }

    hasRecFacial(uoId, solucaoId) {
      let params = {
        uoWithRecFacial: true,
        uoPermissao: uoId,
        size: 1
      };
      if (solucaoId) {
        params.solucaoId = solucaoId;
      }
      return this.restangular
        .one(VEICULO_URL)
        .get(params).then(responseVeiculos => Boolean(responseVeiculos.numberOfElements));
    }

    hasSensor(uoId) {
      return this.restangular
        .one(VEICULO_URL)
        .get({
          uoPermissao: uoId,
          equipamento: this.equipamentosSensorAcionado,
          size: 1
        }).then(responseVeiculos => Boolean(responseVeiculos.numberOfElements));
    }

    getQrCode(params) {
      return this.restangular.all(`${VEICULO_URL}/qrcode`).post(params);
    }
  }

  angular
    .module('cadastros.veiculo')
    .service('CadastroVeiculoService', CadastroVeiculoService);
}());
