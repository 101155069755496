(function () {
  'use strict';

  const ICON_ANCHOR_DEFAULT = [15, 40],
      POPUP_ANCHOR_DEFAULT = [0, -40],
      MAP_ZOOM = 25;

  /* global _, L, window */
  class HistoricoTimelineCtrl {
    constructor($scope, $httpParamSerializer, $filter, $compile, $timeout, $location, FeatureFlag, GoogleTagManagerUtils,
                HistoricoVeiculo, Eventos, MapasUtil, moment, AlertMessage, leafletData, SpiderfierService, MarkerService, EventosEnum, AuditingEventsEnum,
                Keys, ServiceVfiltro, FactoryVfiltro, ObjetosVfiltroService, AGRUPAMENTOS_VALIDOS, ReprodRotasService, $translate, VisaoCamerasService,
                UrlConfigService, Authenticator, HttpServiceV3g, MeasurementUnitConverterService) {
      this.scope = $scope;
      this.filter = $filter;
      this.compile = $compile;
      this.timeout = $timeout;
      this.location = $location;
      this.httpParamSerializer = $httpParamSerializer;
      this.moment = moment;
      this.eventos = Eventos;
      this.leafletData = leafletData;
      this.alertMessage = AlertMessage;
      this.mapasUtilService = MapasUtil;
      this.markerService = MarkerService;
      this.spiderfierService = SpiderfierService;
      this.historicoVeiculoService = HistoricoVeiculo;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.eventosEnum = EventosEnum;
      this.auditingEventsEnum = AuditingEventsEnum;
      this.translate = $translate;
      this.reprodRotasService = ReprodRotasService;
      this.visaoCamerasService = VisaoCamerasService;
      this.featureFlag = FeatureFlag;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.urlConfigService = UrlConfigService;
      this.authenticator = Authenticator;
      this.httpServiceV3g = HttpServiceV3g;
      this.map = null;
      this.markersGroup = new L.FeatureGroup();
      this.pathGroup = new L.FeatureGroup();
      this.historicoInicial = [];
      this.historicoCompleto = [];
      this.historico = [];
      this.historicoMarkers = [];
      this.eventoHistAlerta = '';
      this.eventoData = '';
      this.posicaoItem = null;
      this.tiles = MapasUtil.tiles.veltec;
      this.defaults = MapasUtil.defaults;
      this.geoJson = {};
      this.mapMarkers = [];
      this.mapCenter = MapasUtil.getCenter();
      this.markerSelecionado = null;
      this.deveFocarUltimoCard = false;
      this.ativarDelay = false;
      this.eventosDesativadosPorPadrao = [this.translate.instant(EventosEnum.PONTO_REFERENCIA)];
      this.filtroEventos = [];
      this.eventosAtivos = [];
      this.filtroEventosEvents = this.filtroEventosEventHandler();
      this.agrupamentosValidos = AGRUPAMENTOS_VALIDOS.map(event => this.translate.instant(event));
      this.posicoesReprodRotas = null;
      this.mostraVisaoCameras = false;
      this.markerSnapshot = null;
      this.snapshots = null;
      this.labels = null;
      this.labelsActived = [];
      this.veiculoId = null;
      this.dataReferencia = null;
      this.navigationSetting = null;
      this.leafletData.unresolveMap('historico-map');
      this.setMapEvents();
      this.measurementUnitConverterService = MeasurementUnitConverterService;
      this.authenticator.getUser().then(user => {
        this.usuario = user;
        this.dateTimeFormat = this.measurementUnitConverterService.getDateTimeFormatYearWithTwo(this.usuario.measurementUnits.dateTimeFormat);
        this.speedUnit = this.measurementUnitConverterService.getSpeedUnit(this.usuario.measurementUnits.speedMeasurement);
      });
      this.fraseTooltip = this.translate.instant('ce.mapa.historicoDetalhado.fraseTooltip');
      this.frasePossivelColisaoTooltip = this.translate.instant('ce.mapa.historicoDetalhado.frasePossivelColisaoTooltip');
      this.fraseAcionamentoSemPedalFreio = this.translate.instant('ce.mapa.historicoDetalhado.acionamentoPedalTooltip');
      this.unknownDriver = this.translate.instant('ce.tempoReal.infracoes.common.mensagem.motoristaNaoIdentificado');

      this.verifyMapLayers();
      this.leafletData.getMap('historico-map').then(map => {
        ServiceVfiltro.init(FactoryVfiltro.get([
          {
            name: 'sensor_data.datetime',
            key: this.keys.dateTime.name,
            time: true,
            maxDate: this.moment().endOf('day').format(),
            dateLimit: 1,
            defaultValues: {
              hoje: {
                active: true
              },
              ontem: {
                active: true
              }
            }
          },
          {
            key: this.keys.uo.name,
            size: 6
          },
          {
            key: this.keys.uoParceira.name,
            size: 6
          },
          {
            name: 'sensor_data.vei_id',
            key: this.keys.veiculo.name,
            ignoreValidation: true,
            required: true,
            size: 6
          }
        ]))
        .then((filtros) => {
          this.veiculoId = filtros.find(filtro => filtro.key === this.keys.veiculo.name).value.id;
          this.dataReferencia = filtros.find(filtro => filtro.key === this.keys.dateTime.name).value;

          this.map = map;
          this.init();
        })
        .catch(() => {
          this.location.replace();
          this.leafletData.unresolveMap('historico-map');
          this.serviceVfiltro.openFiltroDetalhado = true;
        });
      });
      this.scope.$on('$destroy', () => {
        this.leafletData.unresolveMap('historico-map');
      });
    }

    init() {
      const querystring = this.location.search(),
          ultimo = querystring.ultimo,
          evento = querystring.evento,
          date = querystring.date;
      if (ultimo) {
        this.ativarDelay = true;
        this.deveFocarUltimoCard = ultimo;
      }
      if (evento) {
        this.eventoHistAlerta = evento;
      }
      if (date) {
        this.eventoData = date;
      }

      this.consultar();
      this.reprodRotasService.showReprodRotas = false;
    }

    getTipoAlerta(alerta) {
      return this.eventos.getEvento(alerta).alerta;
    }

    getSnapshots(currentPage = 0) {
      const labelsSelectedId = this.labelsActived.map(label => label.id);

      if (labelsSelectedId.length === 0) {
        this.snapshots = [];
        return Promise.resolve([]);
      }

      return this.visaoCamerasService.getSnapshots(this.veiculoId, this.dataReferencia, currentPage, labelsSelectedId)
        .then(res => {
          this.navigationSetting = {
            totalPages: res.data.totalPages,
            page: res.data.number,
            first: res.data.first,
            last: res.data.last
          };
          const snapshots = res.data.content;
          snapshots.forEach(snapshot => {
            const nearstPosition = this.findClosestPosition(snapshot.dateTime);
            if (angular.isDefined(nearstPosition)) {
              snapshot.latitude = nearstPosition.latitude;
              snapshot.longitude = nearstPosition.longitude;
            }
          });
          this.snapshots = snapshots;
          return this.snapshots;
        })
        .catch(() => {
          this.alertMessage.create({
            type: 'error',
            message: this.translate.instant('ce.mapa.historicoDetalhado.getSnapshotsErrorMessage'),
            appendTo: '.alerta-aqui'
          });
          return [];
        });
    }

    getLabels() {
      if (this.mostraVisaoCameras && this.labels === null) {
        return this.visaoCamerasService.getLabels(this.dataReferencia, this.veiculoId)
          .then((response) => {
            if (response && response.data.length > 0) {
              this.labels = this.handleFormatLabels(response.data);
              this.labelsActived = angular.copy(this.labels);
            }

            this.loadSnapshots();
          });
      }
    }

    handleFormatLabels(labels) {
      return labels.map(label => {
        return {
          id: label,
          label: this.translate.instant(this.auditingEventsEnum.getDescriptionById(label))
        };
      });
    }

    handleChangePage(page) {
      this.getSnapshots(page);
    }

    filtroEventosEventHandler() {
      return {
        onSelectionChanged: () => {
          this.applyData();
        }
      };
    }

    filterLabelsEvent() {
      this.getSnapshots(0);
    }

    setMapEvents() {
      angular.extend(this.scope, {
        events: {
          map: {
            enable: ['click'],
            logic: 'emit'
          },
          markers: {
            enable: ['click'],
            logic: 'emit'
          }
        }
      });
    }

    consultar() {
      this.historicoVeiculoService.one().get(this.getParams())
      .then(data => {
        this.serviceVfiltro.openFiltroDetalhado = false;
        this.historicoInicial = data.plain();
        this.scope.$broadcast('initPontoReferencia', this.map, this.historicoInicial);
        if (this.historicoInicial.length > 0) {
          try {
            this.applyFiltros();
            this.applyData();
          } catch (error) {
            this.alertMessage.create({
              type: 'error',
              message: this.translate.instant('ce.mapa.historicoDetalhado.consultarErrorMessage'),
              appendTo: '.alerta-aqui'
            });
          }
        } else {
          this.alertMessage.create({
            type: 'warning',
            message: this.translate.instant('v3gwc.vtabela.emptyMessage'),
            appendTo: '.alerta-aqui'
          });
          this.reprodRotasService.setAgrupamentos(null);
        }

        this.setMostraVisaoCameras();
      })
      .catch((erro) => {
        if (erro.status === 500) {
          this.serviceVfiltro.openFiltroDetalhado = true;
        }
      });
    }

    setMostraVisaoCameras() {
      if (this.featureFlag.SNAPSHOTS_HIST_DETALHADO && this.authenticator.hasRole('VIDEO_VISUALIZAR')) {
        this.visaoCamerasService.hasVideo(this.veiculoId).then(hasVideo => {
          this.mostraVisaoCameras = hasVideo;
        });
      }
    }

    loadSnapshots() {
      if (this.snapshots === null) {
        this.getSnapshots(0);
      }
    }

    findClosestPosition(dateTime) {
      const jsDatetime = {jsDate: new Date(dateTime)},
          timeSearching = jsDatetime.jsDate.getTime(),
          indexPosition = _.sortedIndex(this.posicoesReprodRotas, jsDatetime, (obj) => obj.jsDate),
          previousPosition = this.posicoesReprodRotas[indexPosition - 1];
      if (indexPosition >= this.posicoesReprodRotas.length) {
        return previousPosition;
      }

      if (!previousPosition) {
        return this.posicoesReprodRotas[indexPosition];
      }

      let timeFound = this.posicoesReprodRotas[indexPosition].jsDate.getTime(),
          diffPrevious = Math.abs(previousPosition.jsDate.getTime() - timeSearching),
          diffCurrent = Math.abs(timeFound - timeSearching);
      if (diffPrevious < diffCurrent) {
        return previousPosition;
      }
      return this.posicoesReprodRotas[indexPosition];
    }

    applyFiltros() {
      this.filtroEventos = [];

      this.historicoInicial.forEach(item => {
        item.historicos.forEach(hist => this.verificarFiltroEventos(hist));
      });

      this.eventosAtivos = this.getEventosAtivos();
      let posicoes = this.posicoesToPath(
        this.mapasUtilService.ajustarEventos(
          this.converterArvoreEmLista(
            angular.copy(this.historicoInicial))));
      this.setPath(posicoes);
      this.posicoesReprodRotas = posicoes;
    }

    verificarFiltroEventos(historico) {
      if (this.filtroEventos.findIndex(i => i.id === historico.evento) === -1) {
        if (this.isFrenagemBruscaInformacaoPedal(historico)) {
          this.filtroEventos.push(this.insertTipoFrenagemBruscaFiltroEventos(historico));
        }
        this.filtroEventos.push({id: historico.evento, label: historico.evento});
      } else if (this.isFrenagemBruscaInformacaoPedal(historico) &&
      this.filtroEventos.findIndex(i => i.id === this.verificarPedalFreio(historico.acionamentoFreioServico)) === -1) {
        this.filtroEventos.push(this.insertTipoFrenagemBruscaFiltroEventos(historico));
      }
    }

    insertTipoFrenagemBruscaFiltroEventos(historico) {
      const tipoFrenagem = this.verificarPedalFreio(historico.acionamentoFreioServico);
      return {
        id: tipoFrenagem,
        label: tipoFrenagem
      };
    }

    isFrenagemBruscaInformacaoPedal(historico) {
      if (typeof historico.acionamentoFreioServico !== 'undefined') {
        return this.eventos.isFrenagemBrusca(historico.evento);
      }
    }

    verificarPedalFreio(acionamentoPedal) {
      return this.eventos.getTipoFrenagem(acionamentoPedal);
    }

    getEventosAtivos() {
      const evento = this.eventos.getByAlerta(this.location.search().tipoEvento),
          tipoEvento = evento ? evento.tipo : null;

      return this.filtroEventos.filter(
        item => (!tipoEvento || item.id === tipoEvento) &&
          this.eventosDesativadosPorPadrao.indexOf(item.label) === -1
      );
    }

    analisarExibicaoEvento(historico, eventosExibidos) {
      let eventosExibir = eventosExibidos.map(item => item.id);
      if (this.isFrenagemBruscaInformacaoPedal(historico)) {
        if (eventosExibir.indexOf(this.verificarPedalFreio(historico.acionamentoFreioServico)) !== -1) {
          return true;
        }
      } else if (eventosExibir.indexOf(historico.evento) !== -1) {
        return true;
      }
      return false;
    }

    applyData() {
      let data,
          historicoInicial = _.cloneDeep(this.historicoInicial);

      historicoInicial.forEach(item => {
        item.historicos = item.historicos.map(hist =>
          angular.extend(hist, {exibirEvento: this.analisarExibicaoEvento(hist, this.eventosAtivos)})
        );
      });

      data = this.eventosAtivos.length === 0 ? historicoInicial.filter(hist => hist.historicos.length > 0) : historicoInicial;

      this.reprodRotasService.setAgrupamentos(data);
      this.historicoCompleto = data;
      this.hasSomeEventoMovimento = this.reprodRotasService.hasSomeEventoMovimento();
      this.historico = this.mapasUtilService.ajustarEventos(this.converterArvoreEmLista(data));
      this.historicoMarkers = this.mapasUtilService.ajustarEventos(this.converterArvoreEmListaCom1ParadoPorGrupo(data));
      this.insertMarkers();

      if (data.length === 0) {
        this.alertMessage.create({
          type: 'warning',
          message: this.translate.instant('v3gwc.vtabela.emptyMessage'),
          appendTo: '.alerta-aqui'
        });
      }
    }

    exibeReproducaoRotas() {
      return this.posicoesReprodRotas &&
        this.historicoCompleto &&
        this.reprodRotasService.createdReprodRotas &&
        this.hasSomeEventoMovimento;
    }

    mostrarDuracao(posicao) {
      return posicao.duracao > 0 ? ` (${this.filter('humanizedDuration')(posicao.duracao)})` : '';
    }

    getUltimasPosicoes(posicoes) {
      return posicoes.map((p, index) => {
        const isUltima = index === posicoes.length - 1,
            temPosicaoAgrupada = angular.isArray(p.posicoes) && p.posicoes.length > 0;
        return isUltima && temPosicaoAgrupada ? p.posicoes[p.posicoes.length - 1] : p;
      });
    }

    insertMarkers() {
      let oms = this.spiderfierService.createSpiderfier(this.map);
      const posicoes = this.getUltimasPosicoes(this.historicoMarkers);

      this.closeMarker();
      this.markersGroup.clearLayers();
      this.mapMarkers = this.markerService.buildMarkers([posicoes], '', 'historico-', 'timeline').value();
      this.mapMarkers.forEach(m => this.insertMarkersMap(m, oms));

      if (!this.map.hasLayer(this.markersGroup)) {
        this.map.addLayer(this.markersGroup);
      }

      if (this.markersGroup.getBounds().isValid()) {
        this.focusHistorico();
      }

      this.setValueHistoricoAlerta();
      this.setEventoStatusVeiculo();
      this.setEventoHistoricoAlerta();
    }

    focusHistorico() {
      this.map.fitBounds(this.markersGroup.getBounds(), {padding: [100, 100]});
    }

    insertMarkersMap(item, oms) {
      if (item && this.historicoMarkers[item.options.index].exibirEvento) {
        item.setPopupContent(this.compile('<div>' + item.getPopup().getContent() + '</div>')(this.scope)[0]);
        this.markersGroup.addLayer(item);
        if (!this.eventos.isLocalizacao(item.options.evento)) {
          oms.addMarker(item);
        }
      }
    }

    setValueHistoricoAlerta() {
      if (this.eventoHistAlerta && this.eventoData) {
        this.posicaoItem = this.historico.filter(item => {
          return new Date(this.eventoData).getTime() === new Date(item.data).getTime();
        });

        this.ativarDelay = true;
      }
    }

    setEventoHistoricoAlerta() {
      if (this.posicaoItem !== null) {
        if (this.posicaoItem.length > 0) {
          let posicao = null;

          if (this.eventoHistAlerta.indexOf('Diagnóstico') === -1) {
            const evento = this.eventos.getByAlerta(this.eventoHistAlerta),
                tipoEvento = evento ? evento.tipo : evento,
                posicaoEvento = tipoEvento ? this.posicaoItem.find(m => m.evento === tipoEvento) : null;
            posicao = posicaoEvento ? posicaoEvento : this.posicaoItem[0];
          } else {
            const posicaoDiagnostico = this.posicaoItem.find(m => m.evento.indexOf('Diagnóstico') !== -1);
            posicao = posicaoDiagnostico ? posicaoDiagnostico : this.posicaoItem[0];
          }
          this.setFocusItem(posicao);
        } else {
          this.alertMessage.create({
            type: 'error',
            message: this.translate.instant('ce.mapa.historicoDetalhado.historicoAlertaEventoErrorMessage'),
            appendTo: '.alerta-aqui'
          });
        }
      }
    }

    setFocusItem(posicao) {
      if (angular.isDefined(this.historico.filter(m => m === posicao)[0])) {
        this.historico.filter(m => m === posicao)[0].ativo = true;
      }
      this.setFocusMap(posicao);
    }

    setEventoStatusVeiculo() {
      if (this.deveFocarUltimoCard) {
        const posicao = this.historicoMarkers.find(evento => evento.data === this.eventoData);
        this.setFocusItem(posicao);
        this.deveFocarUltimoCard = false;
      }
    }

    getParams() {
      return {
        filtros: this.serviceVfiltro.filtroTopbar && this.serviceVfiltro.filtroTopbar.length > 0 ? this.serviceVfiltro.getFiltroBackendAntigo(this.serviceVfiltro.filtroTopbar, [this.keys.uo.name, this.keys.uoParceira.name]) : '',
        orderBy: 'datetime',
        orderDirection: 'ASC',
        limit: 2000,
        offset: 0
      };
    }

    posicoesToPath(posicoes) {
      return _.chain(this.mapasUtilService.flatPosicoes(posicoes))
        .filter(p => p.gps !== 'Inválido')
        .map(p => {
          p.jsDate = new Date(p.data);
          return p;
        })
        .sortBy(p => p.data)
        .value();
    }

    setPath(posicoes) {
      this.pathGroup.clearLayers();
      let routeLines = L.polyline(posicoes.map(p => [p.latitude, p.longitude]), {
        color: '#3388FF',
        opacity: 0.75,
        weight: 3
      });
      L.polylineDecorator(routeLines, {
        patterns: [{
          offset: 0,
          repeat: 300,
          symbol: L.Symbol.arrowHead({
            pixelSize: 12,
            pathOptions: {fillOpacity: 0.75, color: '#0033FF', weight: 0}
          })
        }]
      }).addTo(this.pathGroup);
      this.pathGroup.addLayer(routeLines);
      if (!this.map.hasLayer(this.pathGroup)) {
        this.map.addLayer(this.pathGroup);
      }

      this.setPathEventos(posicoes);
    }

    converterArvoreEmLista(posicoes) {
      return _(posicoes)
        .map(p => {
          p.movimentos = p.historicos.filter(h => h.evento === this.translate.instant('ce.enum.eventos.movimento') ||
          h.evento === this.translate.instant('ce.enum.eventos.parado'));
          return p.historicos;
        })
        .flatten()
        .value();
    }

    isPossivelColisao(item) {
      return item.descricao === this.translate.instant('ce.enum.common.possivelColisao');
    }

    converterArvoreEmListaCom1ParadoPorGrupo(posicoes) {
      const parado = this.translate.instant('ce.enum.eventos.parado');
      return _(posicoes)
        .map(p => {
          let encontrouUmParado = false;
          return p.historicos.reduce((acc, pos) => {
            if (!encontrouUmParado || acc.length === 0) {
              acc.push(pos);
              if (pos.descricao === parado) {
                encontrouUmParado = true;
              }
            } else if (pos.descricao !== parado) {
              acc.push(pos);
            }
            return acc;
          }, []);
        })
        .flatten()
        .value();
    }

    setPathEventos(posicoes, e) {
      posicoes.filter(p => this.isEvento(p, e)).map(p=> {
        let coord = [[p.latitude, p.longitude]],
            routeEventsLines = null;
        if (angular.isDefined(p.fim) && p.fim !== null) {
          coord = coord.concat(this.getPosicoesDentroDoPeriodoDoEvento(p, posicoes));
          coord.push([p.fim.latitude, p.fim.longitude]);
        }
        routeEventsLines = L.polyline(coord, {
          color: 'red',
          weight: 3
        });
        this.pathGroup.addLayer(routeEventsLines);
      });
      if (!this.map.hasLayer(this.pathGroup)) {
        this.map.addLayer(this.pathGroup);
      }
    }

    getPosicoesDentroDoPeriodoDoEvento(posicao, posicoes) {
      return posicoes
        .filter(p => this.isDentroDoPeriodo(p, posicao))
        .map(p=> [p.latitude, p.longitude]);
    }

    isDentroDoPeriodo(posicao, posicaoPeriodo) {
      return new Date(posicaoPeriodo.data) < new Date(posicao.data) &&
        new Date(posicaoPeriodo.fim.data) > new Date(posicao.data);
    }

    isEvento(p, e) {
      return e === null ? true : angular.isDefined(p.fim) && p.tipo === e;
    }

    setFocusMap(posicao) {
      if (this.markerSelecionado !== null) {
        if (this.eventos.isLocalizacao(this.markerSelecionado.options.evento)) {
          this.markerSelecionado.setIcon(L.divIcon({
            html: this.mapasUtilService.getIconPosicao(),
            popupAnchor: [-2, -32],
            iconAnchor: [15, 40]
          }));
        }
        this.closeMarker();
      }

      this.markerSelecionado = this.mapMarkers[posicao.index];
      this.timeout(() => this.markerSelecionado.openPopup(), this.ativarDelay ? 850 : 0);
      this.ativarDelay = false;
      if (this.eventos.isLocalizacao(this.markerSelecionado.options.evento)) {
        this.markerSelecionado.setIcon(L.divIcon({
          html: this.mapasUtilService.getIconPosicao(),
          popupAnchor: [-2, -32],
          iconAnchor: [15, 40]
        }));
      }

      const start = [posicao.latitude, posicao.longitude],
          last = this.getFimPosicao(posicao),
          end = [last.latitude, last.longitude];

      if (this.isCoordinatesEqual(start, end)) {
        this.map.fitBounds([start, end], {padding: [100, 100]});
      } else {
        this.map.setView(start, MAP_ZOOM);
      }
    }

    getFimPosicao(posicao) {
      if (angular.isDefined(posicao.fim) && angular.isObject(posicao.fim)) {
        return posicao.fim;
      }

      if (angular.isDefined(posicao.posicoes) && posicao.posicoes.length > 0) {
        let posicoes = posicao.posicoes.filter(p => p.gps !== 'Inválido');
        if (posicoes.length > 0) {
          return posicoes[posicoes.length - 1];
        }
      }

      return posicao;
    }

    getIcon(historico) {
      const evento = this.eventos.getEvento(historico.evento),
          removeEfeito = historico.historicos && this.verificaSeTemEventos(historico.historicos) && !this.verificaEventoDiferenteDefault(historico.evento) ? '' : 'remove-efeito';

      if (angular.isDefined(evento)) {
        return `fa ${evento.icone} bg-${evento.cor} ${removeEfeito}`;
      }
      return `fa fa-exclamation-triangle bg-yellow ${removeEfeito}`;
    }

    urlCsv() {
      return `/sensor/relatorio/historico-veiculo/csv?${this.httpParamSerializer(this.getParams())}`;
    }

    downloadCsv() {
      this.httpServiceV3g.get(this.urlCsv(), {responseType: 'blob'})
        .then(result => {
          const contentDispositionHeader = result.headers('content-disposition'),
              fileName = contentDispositionHeader ? contentDispositionHeader.split('filename=')[1] : undefined,
              anchor = angular.element('<a/>');
          anchor.attr({
            href: window.URL.createObjectURL(result.data), //eslint-disable-line
            target: '_blank',
            download: fileName ? fileName : 'export.csv'
          })[0].click();
        });
    }

    verificaMovimentoVelocidadeZero(item) {
      return item.evento === this.translate.instant('ce.enum.eventos.movimento') && item.velocidade === 0;
    }

    getInfoEvento(posicao) {
      if (posicao.evento === this.translate.instant(this.eventos.BANGUELA.tipo)) {
        const [velocidadeInicial, velocidadeFinal, duracao] = posicao.intensidade.match(/\d+/g);
        return `Vel.: ${velocidadeInicial}km/h -> ${velocidadeFinal}km/h, Duração: ${this.filter('humanizedDuration')(duracao)}`;
      } else if (posicao.evento === this.translate.instant('ce.enum.eventos.aberturaDeVigencia') || posicao.evento === this.translate.instant('ce.enum.eventos.fechamentoDeVigencia')) {
        return posicao.evento;
      } else if (this.eventos.isExcessoVelocidade(posicao.evento)) {
        return `${this.translate.instant('ce.mapa.historicoDetalhado.limiteLabel')} ${this.getRoundedSpeed(posicao.limiarSemLabel)}`;
      }
      return posicao.intensidade;
    }

    getRoundedSpeed(stringSpeed) {
      if (!stringSpeed) {
        return '-';
      }
      let speed, unit;
      if (stringSpeed.includes(' ')) {
        [speed, unit] = stringSpeed.split(' ');
      } else {
        // speed should be a number
        // regex to split numeric from non-numeric characters
        [speed, unit] = stringSpeed.split(/(\d+)/).filter(Boolean);
      }
      return `${Math.round(parseFloat(speed))}${unit}`;
    }

    deveMostrar(item) {
      return this.eventos.isExcessoVelocidade(item.evento) ? item.limiar.length > 1 : item.intensidade.length > 1;
    }

    closeMarker() {
      if (this.markerSelecionado !== null) {
        this.markerSelecionado.closePopup();
        this.markerSelecionado = null;
      }
    }

    temLocalizacao(evento) {
      return !!evento.latitude && !!evento.longitude;
    }

    setMarkerAgrupamento(posicao, index) {
      let posicaoAjustado = this.mapasUtilService.ajustaEvento(angular.copy(posicao), index);
      posicaoAjustado.posicao.data = posicaoAjustado.posicao.inicio;
      if (angular.isDefined(posicaoAjustado.posicao.veiculo)) {
        posicaoAjustado.posicao.prefixo = posicaoAjustado.posicao.veiculo.prefixo;
        posicaoAjustado.posicao.placa = posicaoAjustado.posicao.veiculo.placa;
      }
      return this.setMarker(posicaoAjustado.posicao, index);
    }

    setMarker(posicao, index) {
      this.closeMarker();
      const inicio = [posicao.latitude, posicao.longitude],
          ultima = this.getFimPosicao(posicao),
          fim = [ultima.latitude, ultima.longitude];

      this.createMarker(posicao, index);

      this.setMarkerOnMap();
      this.map.fitBounds([inicio, fim], {padding: [100, 100]});
    }

    createMarker(posicao, index) {
      this.markerSelecionado = this.markerService.buildMarker(
        posicao, posicao.evento, index, 'historico-', 'timeline');

      this.markerSelecionado.setIcon(L.divIcon({
        html: this.mapasUtilService.getIconPosicao(),
        popupAnchor: [-2, -32],
        iconAnchor: [15, 40]
      }));
    }

    setMarkerOnMap() {
      if (this.markerSelecionado !== null) {
        this.markersGroup.addLayer(this.markerSelecionado);
        this.markerSelecionado.openPopup();
        this.markerSelecionado.on('popupclose', () => {
          if (this.markerSelecionado !== null) {
            this.markersGroup.removeLayer(this.markerSelecionado);
            this.map.removeLayer(this.markerSelecionado);
          }
        });
      }
    }

    setPontoReferencia(obj) {
      let pontoRef = '';
      if (angular.isDefined(obj.pontoProximo)) {
        if (obj.pontoProximo.distancia === 0) {
          pontoRef += `<strong>${obj.pontoProximo.pontoReferencia.nome}</strong>`;
        } else {
          pontoRef += `${obj.pontoProximo.distancia}m de <strong>${obj.pontoProximo.pontoReferencia.nome}</strong>`;
        }
        pontoRef += ' - ';
      }
      pontoRef += obj.logradouro ? obj.logradouro : '';
      pontoRef += obj.logradouro && obj.cidade ? ', ' : '';
      pontoRef += obj.cidade ? obj.cidade : '';
      return pontoRef;
    }

    verificaEventoDefault(item) {
      return item.historicos.length === 1 && this.verificaEventoDiferenteDefault(item.evento);
    }

    verificaSeTemEventos(item) {
      return this.retornaEventosFiltrados(item) > 0;
    }

    retornaEventosFiltrados(item) {
      return item.filter(x => x.exibirEvento && this.verificaEventoDiferenteDefault(x.descricao)).length;
    }

    verificaEventoDiferenteDefault(item) {
      return !this.agrupamentosValidos.find(itemValido => itemValido === item);
    }

    onLayerSwitch(layerWrapper) {
      this.tiles = layerWrapper.getLayerConfig();
    }

    deveMostrarChovendo(item) {
      return Boolean(item.miscInfo && item.miscInfo.sensorChuva);
    }

    resizeDropdown() {
      this.timeout(() => this.scope.$applyAsync(), 100);
    }

    onVerNoMapaSnapshot(posicao) {
      this.setMarkerSnapshot(posicao.latitude, posicao.longitude, posicao.horario);
    }

    showHistorico() {
      this.removeMarkerSnapshot();
      this.focusHistorico();
    }

    removeMarkerSnapshot() {
      if (this.markerSnapshot) {
        this.map.removeLayer(this.markerSnapshot);
      }
    }

    setMarkerSnapshot(latitude, longitude, datetime = '10:15:55') {
      this.removeMarkerSnapshot();

      this.markerSnapshot = L.marker(
        [latitude, longitude],
        {
          evento: 'snapshot',
          icon: L.divIcon({
            iconSize: [0, 0],
            html: `
              <img src='images/icons-map/marker-yellow.png' class="img-icon"/>
              <span class='fa fa-camera bg-yellow circle-icon'></span>
            `,
            iconAnchor: ICON_ANCHOR_DEFAULT,
            popupAnchor: POPUP_ANCHOR_DEFAULT
          })
        }
      )
      .bindPopup(`
        <p><strong>Snapshot</strong></p>
        <strong>Horário: </strong>${datetime}
      `);

      this.map.addLayer(this.markerSnapshot);
      this.map.setView(L.latLng(latitude, longitude), 17);
      this.markerSnapshot.openPopup();
    }

    sendClickGTM(eventName) {
      this.googleTagManagerUtils.sendEventClickGTM(eventName, this.serviceVfiltro.factoryVfiltro.user);
    }

    onClickCameras() {
      this.googleTagManagerUtils.sendEventClickGTM('historico_detalhado_cameras', this.serviceVfiltro.factoryVfiltro.user);
      this.getLabels();
    }

    onVideoClick() {
      this.googleTagManagerUtils.sendEventClickGTM('historico_detalhado_reproduzir_video_vfleets', this.serviceVfiltro.factoryVfiltro.user);
    }

    verifyMapLayers() {
      if (this.location.search().forceRenderLayer) {
        this.authenticator.getUser().then((user) => {
          this.mapasUtilService.activateAllMapLayers(user.id);
        });
      }
    }

    isAberturaVigencia(historico) {
      return !!historico.snapshotId && !!historico.vigencia && historico.intensidade === 'Abertura de vigência' && historico.vigencia.origem !== 'RFID';
    }

    hasSnapshotNotIgnition(historico) {
      return !!historico.snapshotId && historico.tipoPosicao !== 'IGNICAO_LIGADA';
    }

    hasSnapshotNotVigencia(historico) {
      return !!historico.snapshotId && historico.intensidade !== 'Abertura de vigência';
    }

    hasSnapshotIgnition(historico) {
      return !!historico.snapshotId && historico.tipoPosicao === 'IGNICAO_LIGADA';
    }

    isCoordinatesEqual(start, end) {
      return start && end ? start.toString() === end.toString() : false;
    }

    getEventTooltip(item) {
      const eventName = this.getTipoAlerta(item.descricao);
      return eventName && this.eventos[eventName].tooltip ? this.translate.instant(this.eventos[eventName].tooltip) : null;
    }

    isEventTooltip(item) {
      const eventName = this.getTipoAlerta(item.descricao);
      return eventName ? !!this.eventos[eventName].tooltip : false;
    }

  }

  /**
   * @ngdoc object
   * @name historico-timeline:HistoricoTimelineCtrl
   *
   * @description
   *
   */
  angular
    .module('historicoTimeline')
    .constant('AGRUPAMENTOS_VALIDOS', ['ce.enum.eventos.movimento', 'ce.enum.eventos.parado', 'ce.enum.eventos.gpsInvalido'])
    .controller('HistoricoTimelineCtrl', HistoricoTimelineCtrl);
}());
